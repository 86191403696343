:root {
  --main-text-color: rgb(72, 72, 72);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(247, 244, 239, 0.329);
  color: var(--main-text-color);
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  margin: 20px 50px 50px 50px;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 935px;
  margin: auto;
}

.box {
  box-shadow: -2px 8px 25px -1px rgba(0, 0, 0, 0.055);
  min-height: 20rem;
  margin-top: 30px;
  padding: 20px;
  border-radius: 15px;
  background-color: white;
}

.header {
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 4rem;
}

.speech-box {
  border-bottom: 2px solid #8080801c;
  font-size: 3rem;
  padding-top: 4rem;
  max-height: 8rem;
  white-space: nowrap;
  overflow: scroll;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
}

.rdw-editor-toolbar {
  border: none !important;
  padding-left: 0px !important;
}
.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  transition: all 200ms ease;
  border-radius: 5px !important;
}

.rdw-option-wrapper:hover,
.rdw-dropdown-wrapper:hover,
.rdw-option-active {
  box-shadow: 1px 1px 0px transparent !important;
  background-color: #f7f7f7 !important;
}

.rdw-option-active {
  background-color: #e0f4ffcc !important;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: 1px 1px 0px transparent !important;
  background-color: #ffffff;
}
.rdw-dropdown-carettoopen {
  top: 40% !important;
  border-top: 6px solid #0000003b !important;
}

.rdw-editor-main {
  height: 500px !important;
  max-height: 500px;
}

._37_FJ {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.circle {
  margin-top: 0px;
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 500%;
}

.innercircle {
  background-color: #e44e4e;
  transform: scale(0.6);
}

.outcircle {
  box-shadow: inset 0 0 0px 10px #e44e4e;
  height: 100px;
  width: 100px;
}

.animation31 {
  animation: colorbutton 2s infinite;
}

#x.active {
  animation: colora 2s infinite;
}

#y.active {
  animation: colorb 2s infinite;
  animation-delay: 1s;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@keyframes colorbutton {
  0% {
    box-shadow: inset 0 0 0px 10px #e44e4e;
  }
  50% {
    box-shadow: inset 0 0 0px 10px #ff5d6e;
  }
  100% {
    box-shadow: inset 0 0 0px 10px #e44e4e;
  }
}

@keyframes colora {
  0% {
    background-color: #e44e4e;
    transform: scale(0);
  }
  50% {
    background-color: #ff5d6e;
    transform: scale(0.6);
  }
  100% {
    background-color: #e44e4e;
    transform: scale(0);
  }
}
@keyframes colorb {
  0% {
    background-color: #ff5d6e;
    transform: scale(0);
  }
  50% {
    background-color: #e44e4e;
    transform: scale(0.6);
  }
  100% {
    background-color: #ff5d6e;
    transform: scale(0);
  }
}

.rec-btn-container {
  width: 100px;
  height: 130px;
  position: relative;
}

.rec-button {
  position: absolute;
  right: 0;
  width: 100px;
  height: 100px;
  top: 0;
}

.rec-btn-label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  color: #e44e4e;
}

.rdw-option-wrapper[title="Monospace"],
.rdw-option-wrapper[title="Strikethrough"],
.rdw-option-wrapper[title="Superscript"],
.rdw-option-wrapper[title="Subscript"],
/* .rdw-option-wrapper[title="Indent"],
.rdw-option-wrapper[title="Outdent"], */
.rdw-option-wrapper[title="Justify"],
.rdw-colorpicker-wrapper,
.rdw-fontfamily-wrapper,
.rdw-embedded-wrapper,
.rdw-remove-wrapper {
  display: none !important;
}
